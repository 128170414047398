body{
  height: 100vh;
  width: 100vh;
  margin: 0px;
  padding: 0px;
  background-color: rgb(18, 145, 50);
}

.sidebar{
  position:fixed;
  height: 100vh;
  width: 20vw;
  margin: 0px;
  padding: 0px;
  background-color: rgb(18, 145, 50);
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 50, 50);
}

.menu{
  display: none;
}

.menu-list{
  display: none;
}

.sidebarlist{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  list-style:none;
  color: white;
  margin-inline: 20px;
}
.sidebarlist a{
 color: white;
 text-decoration: none;
 font-size:large;
 font-family:Verdana, Geneva, Tahoma, sans-serif;
 margin-left: 10px;

}
.sidebarlist:hover{
  background-color: rgb(6, 70, 20);
  
 
 }

/* Dashboard */

.sections{
  display:inline-block;
  margin: 0px;
  padding: 0px;
  background-color: rgb(18, 145, 50);
  margin-left: 20vw;
  height: 100vh;
  width: 80vw;
}

.dashboard{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  place-content: center;
}



.span{
  margin-left: 15px;
 
}

.card1{
  background-color: rgb(18, 145, 50);
  height: 200px;
  width: 35vw;
  color: white;
  margin-inline: 20px;
  box-shadow: 0px  0px 7px -1px rgba(0, 0, 0, 35);
}


@media only screen and (max-width:600px) {

  .sidebar{
   display: none;
  }
  
  .sidebarlist{
    display: flex;
    
  }

  .menu{
    display: flex;
    font-size: 25px;
    color: white;
    cursor: pointer;
  }
  .menu-list{
   
    display: none;
    position: relative;
  }
  
  .sections{
    display:flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    background-color: rgb(18, 145, 50);
    height: 100%;
    width: 100%;
    
  }

  .dashboard{
    margin-top: 20px;
    position: relative;
    display:flex;
    flex-direction: column;
    width: 80%;
   align-self: center;
  }
  .card1{
    width: 350px;
    display: flex;
    justify-self: center;
    align-items: center;
    justify-content:right;
    flex-direction:column;
    margin-bottom: 20px;
    
  }
}